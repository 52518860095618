var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    attrs: {
      src: _vm.title + _vm.productDetail.productDetailImage
    }
  })])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };